import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import meterPlaceholder from "../../../assets/images/meter.png";
import ReactSpeedometer, { CustomSegmentLabelPosition, Transition } from "react-d3-speedometer"
import { useDispatch, useSelector } from "react-redux";
import { getMarkettoMeterThunk } from "../../../store/asyncThunks/Marketto";
import { updateMarkettoData } from "../../../store/slices/Marketto/getMarketto";
import { useNavigate } from "react-router-dom";
import { gsap } from 'gsap';

const MarkettoMeter = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const token = localStorage.getItem('auth-user');

  const getMarketto = useSelector((state: any) => state.getMarkettoMeterReducer?.data?.data)
  const authUser = useSelector((state: any) => state.decodeTokenReducer?.data);
  // console.log('====================================');
  // console.log(getMarketto, "getMarketto");
  // console.log('====================================');
  const [percentageValue, setPercentageValue] = useState(50);

  useEffect(() => {
    if (authUser) {
      dispatch(getMarkettoMeterThunk());

      const apiWorker = new Worker(URL.createObjectURL(new Blob([`
      self.addEventListener('message', async (event) => {
        if (event.data === 'start') {
          setInterval(async () => {
            try {
              const headers = {
                'Authorization': 'Bearer ${token}',
                'Content-Type': 'application/json',
              };
              const response = await fetch('${process.env.REACT_APP_BACKEND_URL}/marketto-meter/userslot', { headers });
              const data = await response.json();
              self.postMessage(data);
            } catch (error) {
              console.error('Error fetching API data:', error);
            }
          }, 60000);
        }
      });
    `], { type: 'application/javascript' })));

      apiWorker.postMessage('start');

      apiWorker.addEventListener('message', (event: any) => {
        dispatch(updateMarkettoData(event.data))
      });

      return () => {
        apiWorker.terminate();
      };
    }
  }, [token, authUser]);

  useEffect(() => {
    if (getMarketto?.percentage) {
      setPercentageValue(getMarketto?.percentage)
    } else {
      setPercentageValue(50)
    }
  }, [getMarketto])

  useEffect(() => {
    // GSAP Animation
    const tl = gsap.timeline();
    tl.from('.marketto', {
      opacity: 0,
      y: 50,
      duration: 1.1,
      stagger: 0.5,
    });
  }, []);

  return (
    <div>
      <div className="card marketto">
        {authUser ?
          <div className="trading-time">
            {getMarketto?.isSlotOpen === "YES" ? (
              <>
                <p>Start: <span>{getMarketto?.tradingMeter?.startTime}</span></p>
                <p>End: <span> {getMarketto?.tradingMeter?.endTime}</span></p>
              </>
            ) : (
              <>
                <p className="text-center">{getMarketto?.message}</p>
              </>
            )}

          </div>
          : ''
        }

        <div className="meter-chart">
          {/* <img src={meterPlaceholder} alt="meterplaceHolder" /> */}
          <ReactSpeedometer
            value={percentageValue}
            width={280}
            height={130}
            segments={4}
            maxValue={100}
            needleTransitionDuration={2000}
            needleTransition={Transition.easeElasticIn}
            segmentColors={[
              "#ff0000", "#ff6600", "#32cd32", "#008000"
            ]}
            needleColor="steelblue"
            ringWidth={10}
            customSegmentStops={[0, 25, 50, 75, 100]}
            currentValueText=" "
            currentValuePlaceholderStyle={" "}
            customSegmentLabels={[
              {
                text: "Strong Sell",
                position: CustomSegmentLabelPosition.Outside
              },
              {
                text: "Sell",
                position: CustomSegmentLabelPosition.Outside
              },
              {
                text: "Buy",
                position: CustomSegmentLabelPosition.Outside
              },
              {
                text: "Strong Buy",
                position: CustomSegmentLabelPosition.Outside
              }
            ]}
          />

        </div>
        <div className="text-center">
          <div className="meter-header py-3">
            <p>Eliminate confusion in real-time market trends with automatic updates for every moment of market changes.
              {authUser ? '' : <span> <span className="sign-uplink" onClick={() => navigate('/login')}>Sign In</span> to view the timely updates.</span>}</p>
          </div>
          {/* {authUser ? '' :
            <PrimaryButton btnName={"Sign In"} onClick={() => navigate('/signup')} />} */}
        </div>
      </div>
    </div>
  );
};

export default MarkettoMeter;
