import React from "react";
import { Link } from "react-router-dom";
import instagram from "../../../assets/images/svgicons/social-icons/Instagram.svg";
import facebook from "../../../assets/images/svgicons/social-icons/Facebook.svg";
import twitter from "../../../assets/images/svgicons/social-icons/twitter.svg";
import linkedin from "../../../assets/images/svgicons/social-icons/linkedin.svg";

const SocialMedia = () => {
  return (
    <div>
      <div className="social-links">
        <ul className="d-flex flex-wrap">
          {socmeds.map((item, index) => {
            if (!item?.url) {
              return null;
            }
            return (
              <li className="rounded-circle" key={"key-" + index}>
                <Link to={item?.url} target="_blanck">
                  <img src={item?.icon} alt={item?.name} />
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SocialMedia;

export const socmeds = [
  {
    name: "instagram",
    icon: instagram,
    url: "/",
  },
  {
    name: "linkedin",
    icon: linkedin,
    url: "/",
  },
  {
    name: "twitter",
    icon: twitter,
    url: "/",
  },
  {
    name: "facebook",
    icon: facebook,
    url: "/",
  }
];
