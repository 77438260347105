import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import SpinnerBtn from '../../../components/common/buttons/SpinnerBtn';
import { changePasswordThunk } from '../../../store/asyncThunks/Profile';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { decodeToken } from '../../../store/slices/auth/decodedToken';
import { jwtDecode } from 'jwt-decode';
import PasswordStrengthBar from 'react-password-strength-bar';
import pswShow from '../../../assets/images/svgicons/eye.svg';
import pswHide from '../../../assets/images/svgicons/eye-off.svg';

const ChangePassword = () => {
    const dispatch = useAppDispatch();

    const profile = useAppSelector(state => state.profileUpdateReducer)

    const [watchFields, setWatchFields] = useState<any>();
    const [getpassword, setGetPassword] = useState('');
    const [oldpasswordShow, setOldPasswordShow] = useState(false);
    const [newpasswordShow, setNewPasswordShow] = useState(false);
    const [confPwdShow, setConfPwdShow] = useState(false);

    const ChangepwdForm = {
        oldPassword: "",
        newPassword: "",
        confPwd: ""
    }

    const ChangepwdSchema = yup.object().shape({
        oldPassword: yup.string().required('Enter old password'),
        newPassword: yup.string().required('Enter new password').min(8, 'Password must be at least 8 characters long'),
        confPwd: yup.string().oneOf([yup.ref('newPassword'), ''], 'Passwords must match').required('Enter confirm password'),
    });

    const { register, handleSubmit, setValue, getValues, setError, reset, clearErrors, formState: { errors, }, watch } = useForm({
        resolver: yupResolver(ChangepwdSchema),
        defaultValues: ChangepwdForm
    });

    const onSubmit = (values: any) => {
        const data: any = {};
        data['payload'] = values;
        dispatch(changePasswordThunk(data));
        reset();
    };

    const handleChange = (event: any, field: any) => {
        setValue(field, event?.target?.value);
        setGetPassword(event.target.value);
        if (event.target?.value) {
            clearErrors('newPassword')
        } else {
            setError(field, { type: "custom", message: 'Enter new password' });
        }
    };

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            setWatchFields(value)
        })
        return () => subscription.unsubscribe()
    }, [watch])

    return (
        <div className='profile my-2'>
            <div className='card'>
                <h1 className='title'>Change Password</h1>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className='inpt-item mb-4'>
                        <input type={oldpasswordShow ? "text" : "password"} id='oldPassword' {...register("oldPassword")} className={`${errors.oldPassword?.message ? 'is-invalid ' : ''} form-control ${watchFields?.oldPassword?.length > 0 ? "valid-field" : ""}`} />

                        <label htmlFor='oldPassword' className='inpt-label'>Old password</label>
                        <span>  <button onClick={() => setOldPasswordShow(prevState => !prevState)} type="button" id="password-addon">
                            {oldpasswordShow ? <img src={pswHide} alt="password" /> : <img src={pswShow} alt="password" />}
                        </button></span>
                        {errors.oldPassword?.message ? <span className={`invalid-feedback`} >{errors.oldPassword?.message}</span> : null}
                    </div>
                    <div className='inpt-item mb-4'>
                        <input type={newpasswordShow ? "text" : "password"} id='newPassword' {...register("newPassword")} onChange={(e) => handleChange(e, "newPassword")} className={`${errors.newPassword?.message ? 'is-invalid ' : ''} form-control ${watchFields?.newPassword?.length > 0 ? "valid-field" : ""}`} />
                        <label htmlFor='newPassword' className='inpt-label'>New password</label>
                        <span>  <button onClick={() => setNewPasswordShow(prevState => !prevState)} type="button" id="password-addon">
                            {newpasswordShow ? <img src={pswHide} alt="password" /> : <img src={pswShow} alt="password" />}
                        </button></span>
                        {getpassword.length > 0 && (
                            <PasswordStrengthBar password={getpassword}
                                scoreWords={['Very weak', 'weak', 'Medium', 'Good', 'Strong']}
                                barColors={['#D7D7D7', '#EA1111', '#FFAD00', '#9bc158', '#00b500']}
                            />
                        )}
                        {errors.newPassword?.message ? <span className={`invalid-feedback`} >{errors.newPassword?.message}</span> : null}
                    </div>
                    <div className='inpt-item mb-4'>
                        <input type={confPwdShow ? "text" : "password"} id='confPwd' {...register("confPwd")} className={`${errors.confPwd?.message ? 'is-invalid ' : ''} form-control ${watchFields?.confPwd?.length > 0 ? "valid-field" : ""}`} />
                        <label htmlFor='confPwd' className='inpt-label'>Confirm password</label>
                        <span>  <button onClick={() => setConfPwdShow(prevState => !prevState)} type="button" id="password-addon">
                            {confPwdShow ? <img src={pswHide} alt="password" /> : <img src={pswShow} alt="password" />}
                        </button></span>
                        {errors.confPwd?.message ? <span className={`invalid-feedback`} >{errors.confPwd?.message}</span> : null}
                    </div>
                    <div className="auth-btn">
                        {profile?.loading ?
                            <SpinnerBtn /> :
                            <PrimaryButton btnName="Save" btnType="submit" />
                        }
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChangePassword;