import React, { DragEvent, ChangeEvent, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import PrimaryButton from '../../../../components/common/buttons/PrimaryButton';
import uploadIcon from '../../../../assets/images/svgicons/upload-icon.svg'
import OutlineButton from '../../../../components/common/buttons/OutlineButton';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { addGeneralDetails } from '../../../../store/slices/signupRequest/signupRequest';

const GeneralDetails = ({ setSelectedTab }: any) => {
    const dispatch = useAppDispatch();

    const signupRequestData = useAppSelector(state => state.signupRequest.data);

    const [watchFields, setWatchFields] = useState<any>();

    const max_file_size = 250;

    const ContactFormSchema = yup.object().shape({
        firstName: yup.string().required("Enter first name").max(30, 'First name should not exceed 30 characters').trim(),
        lastName: yup.string().required("Enter last name").max(30, 'Last name should not exceed 30 characters').trim(),
        email: yup.string().email('Enter valid email address').required("Enter email address").max(40, 'Email address should not exceed 30 characters').trim(),
        phoneNumber: yup.string().required("Enter phone number").max(15, 'Phone number should not exceed 15 characters').trim(),
        decription: yup.string().required("Enter decription").max(250, "Description should not exceed 250 characters").trim(),
        profile: yup.mixed().required("Upload profile").test('is-valid-size', 'File size should be less than 500kb', (value: any) => {
            if (value && (value.size / 1024) > max_file_size) {
                return false;
            } else {
                return true;
            }
        })
    });

    const defaultValues = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        decription: "",
        profile: ""
    }

    const { register, handleSubmit, formState: { errors, }, watch, reset, setValue, setError, clearErrors } = useForm({
        resolver: yupResolver(ContactFormSchema),
        defaultValues: defaultValues,
    });

    const onSubmit = (values: any) => {
        const isError = Object?.keys(errors);
        if (isError?.length === 0) {
            const data: any = { ...signupRequestData };
            data['firstName'] = values?.firstName;
            data['lastName'] = values?.lastName;
            data['email'] = values?.email;
            data['phoneNumber'] = values?.phoneNumber;
            data['decription'] = values?.decription;
            data['profile'] = values?.profile;
            dispatch(addGeneralDetails(data));
            setSelectedTab(1);
        }
    }
    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const { files } = event.dataTransfer;
        if (files.length > 0) {
            handleFileChange(files[0]);
        }
    };

    const handleFileChange = (file: File) => {
        if ((file?.size / 1024) > max_file_size) {
            setError('profile', { type: 'custom', message: "File size should be less than 500kb" })
        } else {
            clearErrors('profile')
        }
        setValue('profile', file);
        // You can perform additional actions here with the selected file
    };

    const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files && files.length > 0) {
            handleFileChange(files[0]);
        }
    };

    const preventDefault = (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
    };

    useEffect(() => {
        const subscription = watch((value, { name, type }) =>
            setWatchFields(value)
        )
        return () => subscription.unsubscribe()
    }, [watch])

    useEffect(() => {
        reset(signupRequestData)
    }, [signupRequestData])

    const handleClear = () => {
        reset({ ...signupRequestData, ...defaultValues });
    }

    return (
        <div className='tab-content general-details'>
            <h5>General Info</h5>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className='row'>
                    <div className='col-lg-8'>

                        <div className='form-layout'>
                            <div className='w-100'>
                                <div className='form-group'>
                                    <div className='inpt-item'>
                                        <input type='text' id='fstname' className={`${errors.firstName?.message && "is-invalid "} form-control ${watchFields?.firstName?.length > 0 ? "valid-field" : ""}`} {...register("firstName")} />
                                        <label htmlFor='fstname' className='inpt-label'>First name</label>
                                        {errors.firstName?.message ? <span className={`invalid-feedback`} >{errors.firstName?.message}</span> : null}
                                    </div>
                                    <div className='inpt-item mb-4'>
                                        <input type='text' id='lstname' className={`${errors.lastName?.message && "is-invalid "} form-control ${watchFields?.lastName?.length > 0 ? "valid-field" : ""}`} {...register("lastName")} />
                                        <label htmlFor='lstname' className='inpt-label'>Last name</label>
                                        {errors.lastName?.message ? <span className={`invalid-feedback`} >{errors.lastName?.message}</span> : null}
                                    </div>
                                </div>
                                <div className='form-group w-100'>
                                    <div className='number-grp'>
                                        <div className='inpt-item mb-4'>
                                            <input type='text' id='email' className={`${errors.email?.message && "is-invalid "} form-control ${watchFields?.email?.length > 0 ? "valid-field" : ""}`} {...register("email")} />
                                            <label htmlFor='email' className='inpt-label'>Email</label>
                                            {errors.email?.message ? <span className={`invalid-feedback`} >{errors.email?.message}</span> : null}
                                        </div>
                                        <div className='inpt-item'>
                                            <input type='number' id='mob-num' className={`${errors.phoneNumber?.message && "is-invalid "} form-control ${watchFields?.phoneNumber?.length > 0 ? "valid-field" : ""}`} {...register("phoneNumber")} />
                                            <label htmlFor='mob-num' className='inpt-label'>Mobile number</label>
                                            {errors.phoneNumber?.message ? <span className={`invalid-feedback`} >{errors.phoneNumber?.message}</span> : null}
                                        </div>
                                    </div>
                                    <div className='message-box'>
                                        <div className='inpt-item'>
                                            <textarea className={`${errors.decription?.message && "is-invalid "} form-control ${watchFields?.decription?.length > 0 ? "valid-field" : ""}`} id='message' {...register("decription")}></textarea>
                                            <label htmlFor='message' className='inpt-label'>Decription</label>
                                            {errors.decription?.message ? <span className={`invalid-feedback`} >{errors.decription?.message}</span> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div>
                            <div
                                onDrop={handleDrop}
                                onDragOver={preventDefault}
                                onDragEnter={preventDefault}
                                style={{
                                    border: '2px dashed #ccc',
                                    padding: '20px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                }}
                                className='upload-container'
                            >
                                <input
                                    type="file"
                                    onChange={handleFileInputChange}
                                />
                                <div className='upload-content'>
                                    <div className='upload-icon'>
                                        <img src={uploadIcon} alt='upload' />
                                    </div>
                                    <h5 className='p-0'>Upload Profile Photo</h5>
                                    <p>File Should be png, jpeg, jpg.</p>
                                </div>

                            </div>
                            {watchFields?.profile && (
                                <p>
                                    Selected File: {watchFields?.profile.name} ({(watchFields?.profile.size / 1024).toFixed(2)} KB)
                                </p>
                            )}
                        </div>
                        {errors.profile?.message ? <span className={`invalid-feedback d-block`}>{errors.profile?.message}</span> : null}
                    </div>
                </div>
                <div className='d-flex pt-3 mt-3 justify-content-end'>
                    <OutlineButton btnName={'Clear'} btnType='button' onClick={() => handleClear()} />
                    <PrimaryButton btnName={'Next'} />
                </div>
            </form>
        </div>
    );
};

export default GeneralDetails;