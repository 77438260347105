
import Banner from '../../components/common/banners/Banner'
import aboutBanner from "../../assets/images/about-us-ban.png";
import AboutCompany from './components/AboutCompany';
import AboutVm from './components/AboutVm';
import AboutRoles from './components/AboutRoles';
import AboutTeam from './components/AboutTeam';
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div className='wrapper'>
      <Helmet>
        <title>About Us | FiDeal</title>
      </Helmet>
      <div className="about-ban-content">
        <Banner
          title="About Us"
          description={`Welcome to our financial knowledge-sharing portal designed to link elite investment and trading community people with the rest of the traders and investors. It’s a connecting portal of successful Vs week trading community together. Join hands and update your financial knowledge.`}
          imagePath={aboutBanner}
        />
      </div>
      <section className="large-cap">
        <div className="container">
          <AboutCompany />
        </div>
      </section>
      <section className="large-cap VM-bg">
        <div className="container">
          <AboutVm />
        </div>
      </section>
      <section>
        <AboutRoles />
      </section>
      <section className='large-cap'>
        <AboutTeam />
      </section>
    </div>
  )
}

export default About
