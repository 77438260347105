import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import login from "./slices/auth/login";
import getAdvisor from "./slices/advisors/getadvisor";
import getTopAdvisor from "./slices/advisors/getTopadvisors";
import getTips from "./slices/tips/getTips";
import getadvisorById from "./slices/advisors/getadvisorById";
import getallRecentTips from "./slices/tips/getRecentTips";
import getAdvisorTips from "./slices/tips/getTipsByAdvisor";
import addUserRating from "./slices/advisors/adduserRating";
import getallFaq from "./slices/faq/getallFaq";
import getallCategory from "./slices/category/getallCategory";
import getTipsByAdvisor from "./slices/advisors/getTipsByAdvisorFilter"
import ReviewRating from "./slices/advisors/reviewRating";
import AddContactUS from "./slices/contactus/ContactUs";
import signup from "./slices/auth/signup";
import emailVerification from "./slices/auth/emailVerifiy";
import forgotPassword from "./slices/auth/forgotpassword";
import resetPassword from "./slices/auth/resetPassword";
import emailVerificationResent from "./slices/auth/resentVerifiy";
import getMarketto from "./slices/Marketto/getMarketto";
import signupRequest from "./slices/signupRequest/signupRequest";
import decodeTokenSlice from "./slices/auth/decodedToken";
import notificationSlice from "./slices/tips/notification";
import profileUpdate from "./slices/profile/profileUpdate";
import changePassword from "./slices/profile/changePassword";
import getProfileImg from "./slices/profile/getProfileImg";
import addSignupRequest from "./slices/signupRequest/addSignupRequest";

const store = configureStore({
  reducer: {
    loginReducer: login,
    signupReducer: signup,
    emailVerifiyReducer: emailVerification,
    emailVerifiyResentReducer: emailVerificationResent,
    forgotPasswordReducer: forgotPassword,
    resetPasswordReducer: resetPassword,
    getAdvisorReducer: getAdvisor,
    topAdvisorReducer: getTopAdvisor,
    getTipsReducer: getTips,
    getadvisorByIDReducer: getadvisorById,
    RecentTipsReducer: getallRecentTips,
    getTipsByAdvisorReducer: getAdvisorTips,
    UserRatingReducer: addUserRating,
    getFaqReducer: getallFaq,
    getCategoryReducer: getallCategory,
    getTipsByAdvisorFilterReducer: getTipsByAdvisor,
    reviewRatingReducer: ReviewRating,
    contactUsEnquiryReducer: AddContactUS,
    getMarkettoMeterReducer: getMarketto,
    signupRequest: signupRequest,
    decodeTokenReducer: decodeTokenSlice,
    notificationReducer: notificationSlice,
    profileUpdateReducer: profileUpdate,
    changePasswordReducer: changePassword,
    getProfileImgReducer: getProfileImg,
    addSignupRequestReducer: addSignupRequest,

  },
});
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
