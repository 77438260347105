import MemberOne from "../../assets/images/svgimage/member-1.svg";
import MemberTwo from "../../assets/images/svgimage/member-2.svg";
import MemberThree from "../../assets/images/svgimage/member-3.png";
import MemberFour from "../../assets/images/svgimage/member-4.png";


export const teamMembers = [
    {
        name: 'Sean Farmer',
        role: 'Co-Founder',
        image: MemberOne
    },
    {
        name: 'Sean Farmer',
        role: 'Co-Founder',
        image: MemberTwo
    },
    {
        name: 'Sean Farmer',
        role: 'Co-Founder',
        image: MemberThree
    },
    {
        name: 'Sean Farmer',
        role: 'Co-Founder',
        image: MemberFour
    },
];
