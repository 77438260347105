import React, { useEffect } from "react";
import SecondaryButton from "../../common/buttons/SecondaryButton";
import { useNavigate } from "react-router-dom";
import Aos from "aos";

const ContactBlog = () => {
  const navigate = useNavigate();

  const contactPath = () => {
    navigate('/contact-us')
  }
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="shape-container" data-aos="zoom-in">
      <div className="footer-shape">
        <div className="">
          <h2>Need more information?</h2>
          <p>
            Write your concern to us and our specialist will get back to you.
          </p>
        </div>
        <div>
          <SecondaryButton btnName={"Contact Us"} btnType={"button"} onClick={contactPath} />
        </div>
      </div>
    </div>
  );
};

export default ContactBlog;
