import React, { useContext, useEffect, useState } from 'react';
import bell from '../../../assets/images/svgicons/bell.svg'
import { WebsocketContext, socket } from '../../../constants/socket/socket';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { updateNotification } from '../../../store/slices/tips/notification';
import { useNavigate } from 'react-router-dom';

type MessagePayload = {
    content: string;
    msg: string;
};

const Notification = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const newNotification = useAppSelector(state => state.notificationReducer.data);

    const socket = useContext(WebsocketContext);

    useEffect(() => {
        socket.on('connect', () => {
            console.log('Connected!');
        });
        socket.on('newTip', (newMessage: MessagePayload) => {
            console.log('newTip received!');
            if (newMessage) {
                dispatch(updateNotification(true))
            }
        });
        return () => {
            console.log('Unregistering Events...');
            socket.off('connect');
            socket.off('newTip');
        };
    }, []);

    const handleBellClick = () => {
        // if (newNotification) {
        navigate('/geniustalk')
        // }
    }

    return (
        <div className='notify-head position-relative'>
            <img className={`${newNotification ? 'bell' : ""}`} src={bell} alt='bell' onClick={() => handleBellClick()} />
            {
                newNotification &&
                <div className='notify-count'></div>
            }
        </div>
    );
};

export default Notification;