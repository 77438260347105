import { Link } from "react-router-dom";
import location from "../../../assets/images/svgicons/location.svg";
import email from "../../../assets/images/svgicons/email.svg";
import phone from "../../../assets/images/svgicons/phone.svg";
import insta from "../../../assets/images/svgicons/social-icons/Instagram.svg";
import fb from "../../../assets/images/svgicons/social-icons/Facebook.svg";
import twitter from "../../../assets/images/svgicons/social-icons/twitter.svg";
import ln from "../../../assets/images/svgicons/social-icons/linkedin.svg";

const ContactInfo = () => {
  return (
    <div className="content-left">
      <div className="address">
        <h1>Contact Info</h1>
        <div className="details" data-aos="fade-up">
          <div className="address-logo">
            <img src={location} alt="location" />
          </div>
          <div className="address-landmark">
            <p>
              805, Dummy number insert<br /> 5th street, Dummy door <br />Dummy state and country

              <span className="pt-0 d-block">Pincode - 999000</span>
            </p>
          </div>
        </div>
        <div className="details" data-aos="fade-up">
          <div className="address-logo">
            <img src={phone} alt="phone" />
          </div>
          <div className="address-landmark">
            <p>
              <Link to="tel:+91 78122 54451">+91 78122 54451 </Link>
              <Link to="tel:+91 98154 42154">+91 98154 42154</Link>
            </p>
          </div>
        </div>
        <div className="details" data-aos="fade-up">
          <div className="address-logo">
            <img src={email} alt="email" />
          </div>
          <div className="address-landmark">
            <p>
              <Link to="mailto:info@fideal.com">info@fideal.com</Link>
            </p>
          </div>
        </div>

        <div className="socialmedia" data-aos="fade-up">
          <p>Social Media</p>
          <div className="social-logo">
            <Link to="">
              <img src={insta} alt="instagram" />
            </Link>
            <Link to="">
              <img src={ln} alt="linked_in" />
            </Link>
            <Link to="">
              <img src={twitter} alt="twitter" />
            </Link>
            <Link to="https://www.facebook.com/">
              <img src={fb} alt="facebook" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
