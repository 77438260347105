import React from "react";

const Address = () => {
  return (
    <div>
      <div className="ads-blog">
        <h5 className="foot-title">Address</h5>
        <p>
          805, Dummy number insert 5th street, Dummy door Dummy state and
          country Pincode - 999000
        </p>
      </div>
    </div>
  );
};

export default Address;
