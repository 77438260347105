import { teamMembers } from "../../../constants/data/TeamInfo";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import Insta from "../../../assets/images/svgicons/social-icons/insta-icon.svg";
import Twit from "../../../assets/images/svgicons/social-icons/twitter-icon.svg";
import Facebook from "../../../assets/images/svgicons/social-icons/facebook-icon.svg";
import { useEffect } from "react";
import Aos from "aos";


const TeamMember = ({ name, role, image }: any) => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    return (
        <div className="thumb-info" data-aos="fade-up">
            <div className="member-img">
                <img src={image} alt="Profile-Photo" />
                <div className="overlay">
                    <Link to={"#"} target="_blanck">
                        <div className="circle">
                            <img src={Insta} alt="Instagram" />
                        </div>
                    </Link>
                    <Link to={"#"} target="_blanck">
                        <div className="circle">
                            <img src={Twit} alt="Facebook" />
                        </div>
                    </Link>
                    <Link to={"#"} target="_blanck">
                        <div className="circle">
                            <img src={Facebook} alt="Twitter" />
                        </div>
                    </Link>
                </div>
            </div>
            <div className="text-center member-name">
                <h5>{name}</h5>
                <p>{role}</p>
            </div>
        </div>
    );
};


const AboutTeam = () => {
    return (
        <>
            <div className="team-info">
                <div className="container">
                    <h2 className="text-center" data-aos="fade-up">Our<span className="text-Gradient"> Team</span></h2>
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={25}
                        // loop={true}
                        // autoplay={{
                        //     delay: 3500,
                        //     disableOnInteraction: false,
                        // }}
                        pagination={{
                            clickable: false,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            575: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1200: {
                                slidesPerView: 4,
                            }
                        }}
                    >
                        {teamMembers.map((member, index) => (
                            <SwiperSlide key={index}>
                                <TeamMember
                                    name={member.name}
                                    role={member.role}
                                    image={member.image}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </>
    );
}

export default AboutTeam