export const idProofOptions = [
  { label: "Aadhaar Card", value: "AADHAAR_CARD" },
  { label: "Pan Card", value: "PAN_CARD" },
  { label: "Voter Id", value: "VOTER_ID" },
  { label: "Driving Licence", value: "DRIVING_LICENCE" },
];

export const addressProofOptions = [
  { label: "Aadhaar Card", value: "AADHAAR_CARD" },
  { label: "Voter Id", value: "VOTER_ID" },
  { label: "Driving Licence", value: "DRIVING_LICENCE" },
];
