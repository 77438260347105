import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import fullStar from "../../../assets/images/svgicons/full_star.svg";
import unFillStar from "../../../assets/images/svgicons/emptyStar.svg";
import { useState } from "react";
import { addRatingThunk } from "../../../store/asyncThunks/Rating";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import ReviewModal from "../../../components/common/modal/reviewModal";
import SpinnerBtn from "../../../components/common/buttons/SpinnerBtn";
import { useAppSelector } from "../../../store/store";

interface props {
  starCount: number;
  setStarCount: any;
  advisorId: string;
}

const SubmitReview = (props: props) => {
  const { starCount, setStarCount } = props;

  const dispatch = useDispatch<any>();

  const addRatingLoading = useAppSelector(state => state.UserRatingReducer.loading);

  const [showModal, setShowModal] = useState(false);

  const schema = yup.object({
    review: yup.string().required('Enter review').max(250, 'Review should not exceed 250 characters'),
    starCount: yup.number().required('Select rating').min(1, 'Select atleast one star'),
  });
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    clearErrors,
    setError,
    formState: { errors, },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      starCount: 0,
      review: ""
    }
  });

  const handleSuccess = () => {
    reset()
    setStarCount(0)
    setShowModal(true);
  }


  const onSubmit = (values: any) => {
    const data = {
      advisorId: props.advisorId,
      rating: values?.starCount,
      review: values?.review,
    };
    dispatch(addRatingThunk({ data: data, afterSuccess: handleSuccess }));
  };

  const handleStarChange = (index: number) => {
    if (starCount !== index + 1) {
      setStarCount(index + 1);
      setValue("starCount", index + 1)
      clearErrors("starCount")
    } else {
      setStarCount(0);
      setValue("starCount", 0)
      setError('starCount', { type: 'custom', message: 'Select atleast one star' });
    }
  }

  return (
    <div className="review col-md col-lg-8">
      <form onSubmit={handleSubmit(onSubmit)}      >
        <div className="review-input">
          <label className="review-label">Your Review*</label>
          <textarea
            className={`${errors?.review?.message ? 'is-invalid ' : ''} custom-input custom-scroll`}
            {...register('review')}
          />
          {errors.review?.message ? <p className={`invalid-feedback`} >{errors.review?.message}</p> : null}
        </div>
        <div className="action">
          <div>
            <p>
              Rating <span>(Click to Rate)</span>
            </p>
            <div className="stars">
              {[...Array(5)].map((item, index) => {
                return (
                  <div
                    className={`star-image `}
                    onClick={() => handleStarChange(index)}
                  >
                    <img
                      src={starCount >= index + 1 ? fullStar : unFillStar}
                      alt="star"
                    />
                  </div>
                );
              })}
            </div>
            {errors?.starCount && <p className="invalid-feedback d-block">{errors?.starCount?.message}</p>}

          </div>
          <div className="load-more-button mt-5">
            {
              addRatingLoading ? <SpinnerBtn /> :
                <PrimaryButton btnName="Submit" btnType='submit' />
            }
          </div>
          {/* <div className="button">
            <PrimaryButton btnName="Submit" type='submit' />
          </div> */}
        </div>
      </form>
      {showModal &&
        <ReviewModal
          showModal={true}
          toggle={() => setShowModal(false)}
          modalTitle="Review Submitted!!!"
          description="Thank you for submitting your review!. It will be reviewed and published by admin on application."
          primaryBtn="Close"
          customStyles="your-custom-styles-here"
        />}

    </div >
  );
};

export default SubmitReview;
