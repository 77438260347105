import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { contactUsThunk } from "../../../store/asyncThunks/ContactUs";
import { useAppSelector } from "../../../store/store";
import SpinnerBtn from "../../../components/common/buttons/SpinnerBtn";
import Aos from "aos";

const ContactForm = () => {

  const dispatch = useDispatch<any>();

  const addContactUsLoader = useAppSelector(state => state.contactUsEnquiryReducer.loading);

  const [watchFields, setWatchFields] = useState<any>();

  const ContactForm = {
    fName: "",
    lName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: ""
  }

  const ContactFormSchema = yup.object().shape({
    fName: yup.string().required("Enter first name").max(30, 'First name should not exceed 30 characters').trim(),
    lName: yup.string().required("Enter last name").max(30, 'Last name should not exceed 30 characters').trim(),
    email: yup.string().email('Enter valid email address').required("Enter email address").max(40, 'Email address should not exceed 30 characters').trim(),
    phoneNumber: yup.string().required("Enter phone number").max(15, 'Phone number should not exceed 15 characters').trim(),
    subject: yup.string().required("Enter subject").max(60, "Subject should not exceed 60 characters").trim(),
    message: yup.string().required("Enter message").max(200, "Message should not exceed 200 characters").trim(),
  });
  const { register, handleSubmit, setValue, getValues, reset, clearErrors, formState: { errors, }, watch } = useForm({
    resolver: yupResolver(ContactFormSchema),
    defaultValues: ContactForm
  });
  const handleSuccess = () => {
    reset()
  }
  const onSubmit = (values: any) => {
    dispatch(contactUsThunk({ data: values, enquirySuccess: handleSuccess }));
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      setWatchFields(value)
    )
    return () => subscription.unsubscribe()
  }, [watch])
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="content-right" data-aos="fade-up">
      <h2>
        Get in <span>Touch</span>
      </h2>
      <p>
        Have an inquiry or feedback for us? Fill out the form below to contact our team.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} >
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input id="1" type="text" {...register('fName')}
                className={`${errors.fName?.message ? 'is-invalid ' : ''} form-control ${watchFields?.fName?.length > 0 ? "valid-field" : ""}`} />
              <label htmlFor="1" className="placeholder">
                First Name
              </label>
              {errors.fName?.message ? <span className={`invalid-feedback`} >{errors.fName?.message}</span> : null}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <input id="2" type="text"  {...register("lName")}
                className={`${errors.lName?.message ? 'is-invalid ' : ''} form-control ${watchFields?.lName?.length > 0 ? "valid-field" : ""}`} />
              <label htmlFor="2" className="placeholder">
                Last Name
              </label>
              {errors.lName?.message ? <span className={`invalid-feedback`} >{errors.lName?.message}</span> : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input id="3" type="text"  {...register("email")}
                className={`${errors.email?.message ? 'is-invalid ' : ''} form-control ${watchFields?.email?.length > 0 ? "valid-field" : ""}`} />
              <label htmlFor="3" className="placeholder">
                Email
              </label>
              {errors.email?.message ? <span className={`invalid-feedback`} >{errors.email?.message}</span> : null}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <input id="4" type="number" {...register("phoneNumber")}
                className={`${errors.phoneNumber?.message ? 'is-invalid ' : ''} form-control ${watchFields?.phoneNumber?.length > 0 ? "valid-field" : ""}`} />
              <label htmlFor="4" className="placeholder">
                Mobile Number
              </label>
              {errors.phoneNumber?.message ? <span className={`invalid-feedback`} >{errors.phoneNumber?.message}</span> : null}
            </div>
          </div>
        </div>
        <div className="form-group">
          <input id="5" type="text"  {...register('subject')}
            className={`${errors.subject?.message ? 'is-invalid ' : ''} form-control ${watchFields?.subject?.length > 0 ? "valid-field" : ""}`} />
          <label htmlFor="5" className="placeholder">
            Subject
          </label>
          {errors.subject?.message ? <span className={`invalid-feedback`} >{errors.subject?.message}</span> : null}
        </div>
        <div className="form-group">
          <textarea id="6"  {...register("message")}
            className={`${errors?.message?.message ? 'is-invalid ' : ''} ${watchFields?.message?.length > 0 ? "valid-field " : ""} form-control  custom-input custom-scroll`}  >
          </textarea>
          <label htmlFor="6" className="placeholder">
            Message
          </label>
          {errors.message?.message ? <span className={`invalid-feedback`} >{errors.message?.message}</span> : null}
        </div>
        <div className="load-more-button pt-2 d-block">
          {
            addContactUsLoader ? <SpinnerBtn /> :
              <PrimaryButton btnName="Submit" btnType='submit' />
          }
        </div>
      </form>
    </div >
  );
};

export default ContactForm;
